import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import styled from "@emotion/styled";
import { colors, screenSize, quicksandFont } from "../utils/css/themes";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import { CalendarEvent } from "../pages/events";
import DangerousHtml from "../components/Helpers/DangerousHtml";

const EventSubtitle = styled.h3`
  font-family: ${quicksandFont.medium};
  font-size: 21px;
  font-weight: 500;
  color: ${colors.blue};
`;
const DetailHeader = styled.h4`
  font-family: ${quicksandFont.bold};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: ${colors.blue};
`;
const Detail = styled.h3`
  font-family: ${quicksandFont.regular};
  font-size: 21px;
  font-weight: 500;
  color: ${colors.darkGrey};
`;
const Content = styled.div`
  margin-top: 25px;
  @media (max-width: ${screenSize.largePhone}) {
    padding: 0 20px 0;
  }
`;
const RegistrationLink = styled.a`
  font-family: ${quicksandFont.bold};
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  background-color: ${colors.green};
  color: white;
  padding: 0.75rem 2.5rem;
  letter-spacing: 1px;
  justify-content: flex-end;
  border-radius: 1.5rem;
  font-weight: bold;
  font-size: 0.75rem;
  font-weight: bold;
  margin: auto 0;
  border: 1px solid ${colors.green};
`;
const EventTemplate = (props: any) => {
  const eventData: CalendarEvent = props.data.wpPost;
  if (!eventData?.eventsType) {
    return (
      <Layout title={eventData?.title || "Event Not Found"}>
        <p>Could not find data for event.</p>
      </Layout>
    );
  }
  return (
    <Layout
      title={eventData.title}
      backButton={{ link: "/events", label: "Events" }}
    >
      <SEO title={eventData.title} />
      <div>
        <figure
          css={css`
            width: 367px;
            float: left;
            @media (max-width: ${screenSize.largePhone}) {
              float: none;
              width: 100%;
              height: auto;
              margin-bottom: 2rem;
            }
            margin: 25px 1rem 1rem 0;
          `}
        >
          {eventData.eventsType?.eventImage.localFile && (
            <GatsbyImage
              image={
                eventData.eventsType.eventImage.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt=""
            />
          )}
          <figcaption>{eventData.eventsType.eventImageSubtitle}</figcaption>
        </figure>
        <Content>
          <EventSubtitle>{eventData.eventsType.eventSubtitle}</EventSubtitle>
          <DetailHeader>WHEN:</DetailHeader>
          <Detail>
            {eventData.eventsType.eventDate} | {eventData.eventsType.startTime}
          </Detail>
          <DetailHeader>WHERE:</DetailHeader>
          <Detail>{eventData.eventsType.eventAddress}</Detail>
          {eventData.eventsType.eventCost && (
            <span>
              <DetailHeader>COST:</DetailHeader>
              <Detail>{eventData.eventsType.eventCost}</Detail>
            </span>
          )}
          <DetailHeader>More Information:</DetailHeader>
          <DangerousHtml>{eventData.content}</DangerousHtml>
          <div
            css={css`
              margin: 0 auto;
              margin-top: 40px;
              margin-bottom: 40px;
              width: fit-content;
            `}
          >
            {eventData.eventsType.eventRegistration && (
              <RegistrationLink
                href={`${eventData.eventsType.eventRegistration}`}
              >
                Click here to register
              </RegistrationLink>
            )}
          </div>
        </Content>
      </div>
    </Layout>
  );
};
export default EventTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      eventsType {
        type
        featuredEvent
        eventImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        eventDate
        startTime
        eventRegistration
        eventDescription
        eventCost
        eventSubtitle
        eventAddress
        eventImageSubtitle
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      thumbnail: featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 150
                transformOptions: { cropFocus: CENTER }
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`;
